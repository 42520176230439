@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.tasklist-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Montserrat;
  text-align: center;
  justify-items: center;
  gap: 16px;
  margin-top: 20px;
}

.outer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
}

.add-task-button {
  background-color: #007bff;
  color: white;
  text-align: center;
  height: 30px;
  width: 105px;
  border-radius: 5px;
  border: none;
  font-size: medium;
  cursor: pointer;
}
.home-tab {
  font-weight: 600;
  font-size: large;
}

.table-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 90%;
  overflow: auto;
  /* padding-right: 14px; */
}

.tasklist-table {
  border-collapse: separate; /* Ensure this is set to separate */
  border-spacing: 0px 2px; /* Remove spacing between cells */
  table-layout: fixed;
  width: 100%;
}

.col-sNo {
  width: 80px;
  padding-left: 20px;
}

.col-date {
  width: 100px;
}

.col-name {
  width: 200px;
}

.col-details {
  width: 220px;
}

.col-status {
  width: 150px;
  padding: 10px;
}

.col-view {
  width: 150px;
}

.tasklist-table-field {
  background-color: #efefef;
  border-top-left-radius: 5px;
  height: 50px;
}

.header-row th:first-child {
  border-top-left-radius: 8px; /* Round the left corner */
}

.header-row th:last-child {
  border-top-right-radius: 8px; /* Round the right corner */
}

.task {
  color: #676565;
  font-size: 16px;
  font-weight: 600;
  height: 45px;
}
.task td {
  border-bottom: 1px solid #efefef;
  padding: 2px;
}
.task .task-details,
.task .task-name {
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis;
}

.refresh-status-button {
  cursor: pointer;
  background-color: white;
  color: black;
  transition: background-color 0.3s ease;
  border-radius: 2px;
  padding: 1px;
  border: 1px solid black;
}
.refresh-status-button:hover {
  background-color: gainsboro;
}
.refresh-status-button:disabled {
  cursor: not-allowed;
  color: #b4a9a9a2;
  border: 1px solid #b4a9a9a2;
}

.tippy-box[data-theme~="custom"] {
  background-color: #333;
  color: white;
  font-size: 14px;
  border-radius: 5px;
  padding: 5px;
  opacity: 0.9;
}

.task-status-header {
  width: 110px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 14px;
  margin: auto;
}

.task-status-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.task-status-Incomplete-text {
  color: #ef4044;
  background-color: #fbdbdd;
  border-radius: 5px;
  text-align: center;
  width: 145px;
  display: block;
  align-self: center;
  padding: 5px;
  margin: auto;
  line-height: 23px;
}

.task-status-Complete-text {
  color: #35aa5b;
  background-color: #c8efd3;

  border-radius: 8px;
  text-align: center;
  padding: 5px;
  width: 145px;
  display: block;
  /* margin: auto; */
  line-height: 23px;
}
.task-status-Failed-text {
  color: #ef4044;
  background-color: #efc8c8;
  border-radius: 8px;
  text-align: center;
  padding: 5px;
  width: 145px;
  display: block;
  /* margin: auto; */
  line-height: 23px;
}

.task-status-In-progress-text {
  color: #9e9a42;
  background-color: lightyellow;
  border-radius: 8px;
  text-align: center;
  padding: 5px;
  width: 145px;
  display: block;
  /* margin: auto; */
  line-height: 23px;
}

.task-status-Pending-text {
  color: #f69a26;
  background-color: #fee7ca;
  border-radius: 5px;
  text-align: center;
  padding: 5px;
  width: 145px;
  display: block;
  /* margin: auto; */
  line-height: 23px;
}

.action-div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.task-view-button {
  background-color: #007bff;
  color: white;
  text-align: center;
  width: 80px;
  line-height: 30px;
  border-radius: 5px;
  border: none;
  font-size: medium;
  margin-right: 15px;
  margin-left: 15px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}
.task-view-button:hover,
.add-task-button:hover {
  background-color: #57a0ef;
}
.task-view-button:active,
.add-task-button:active {
  background-color: #007bff;
}
.task-view-button:disabled {
  cursor: not-allowed;
  background: #abd1f9;
}

.task-delete-button {
  color: #fa3205;
  margin: 0;
  padding: 0;
  height: fit-content;
  width: fit-content;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.task-delete-button:hover {
  color: #e7836d;
  transition: margin-right 2s;
}
.task-delete-button:disabled {
  cursor: not-allowed;
  color: #e7836d;
}
.task-delete-button:active {
  color: #e75837;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
}
