
  *::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  *::-webkit-scrollbar-track {
    background-color: white;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: #aaaaaa;
    border-radius: 10px;
  }
  
  *::-webkit-scrollbar-thumb:hover {
  }
