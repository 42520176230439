@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");


.add-task-modal{
  padding: 15px 35px;
}
.add-task-header {
  color: rgba(51, 51, 51, 0.8) !important;
  text-align: center !important;
  font-family: Montserrat !important;
  font-size: x-large !important  ;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}
.add-task-form {
  gap: 30px;
  display: flex;
  flex-direction: column;
  font-family: montserrat;
  padding: 25px 0px;
}

.add-task-labels {
  font-size: large !important;
  margin: 4px auto !important;

}

.add-task-input{
  font-size: 14px;
}

.add-task-file-labels {
  font-size: large !important;
  margin: auto !important;
}
.file-inputs {
  display: grid;
  grid-template-columns: 48% 48%;
  gap: 26px;
}

.advik-file-field,
.telecom-file-field {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding: 20px 0px;
  gap: 10px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
}

.add-task-details-input {
  background-color: #f8fafb !important;
}

.advik-file-input,
.telecom-file-input {
  border-radius: 4px;
  border: 0.9px dashed #007bff;
  margin: 5px;
  padding: 20px;
  font-size: small;
  width: 90%;
  height: 111px;
}

.advik-file-input:hover,
.telecom-file-input:hover{
  cursor: pointer;

}

.advik-file-input-isDisabled,
.telecom-file-input-isDisabled
{
  border-radius: 4px;
  color: grey;
  border: 0.9px dashed grey;
  margin: 5px;
  padding: 20px;
  font-size: small;
  width: 90%;
  height: 111px;
  cursor: not-allowed; 
  /* not changing pointer */
}
.advik-file-input-inner-container ,
.telecom-file-input-inner-container{
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.file-container{
  text-align: center;
  display: flex;
  flex-direction: row;
}
.file-name{
  padding: 2px;
  white-space: nowrap;
  height: 30px;
}

.file-placeholder{
  padding: 2px;
  height: 30px;
}

.file-upload-icon {
  color: #007bff;
}


.add-task-modal-footer{
 align-self: center !important;
 width: 150px !important;
}


.submit-add-task-form {
  color: #fff !important;
  font-family: Montserrat !important;
  border-radius: 10px !important;
  background: #007bff !important;
}

.submit-add-task-form:hover{
  background-color: #57a0ef !important;
  transition: margin-right 2s;
}

.add-task-modal .loader{
  position: fixed;
  top:48%;
  left: 47%;
  
}
.required-asterik{
  color: red;
}