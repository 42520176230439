.paginate-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  /* margin-top: auto; */
  height: 50px;
  align-items: center;
  background-color: #efefef;
  border-radius: 4px;
  padding: 12px 50px;
}
.page-details {
  font-weight: 600;
}
.paginate-button {
  display: flex;
  align-items: center;
  gap: 20px;

}
/* .paginate-button > button {
  border: 1px solid black;
  border-radius: 4px;
  background-color: white;
  color: black;
  transition: background-color 0.3s ease;
} */

.currentPage {
  font-weight: 600;
}

/* .left-push-button,
.right-push-button,
.left-button,
.right-button {
  border: 1px solid black;
  border-radius: 4px;
  background-color: white;
  color: black;
  transition: background-color 0.3s ease;
} */

.pageSizeOpt {
  background-color: #efefef;
  border: none;
}
/* .paginate-button > button:hover {
  background-color: gainsboro;
}
.paginate-button > button:disabled {
  cursor: not-allowed;
  color: #b4a9a9a2;
  border: 1px solid #b4a9a9a2;
} */

/* .left-push-button:hover,
.right-push-button:hover,
.left-button:hover,
.right-button:hover {
  background-color: gainsboro;
} */
/* 
.left-push-button:disabled,
.right-push-button:disabled,
.left-button:disabled,
.right-button:disabled{
  cursor: not-allowed;
  color: #b4a9a9a2;
  border: 1px solid #b4a9a9a2;
} */
