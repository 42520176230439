@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.navbar{
    background-color: #1a84f6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    justify-content: space-between;
    padding: 1rem 5rem 1rem 5rem;
    font-family: Montserrat;
    color: white;
    z-index: 100;
}

.databridge-logo{
    width:2.5rem ;
}

.nav-brand{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    gap: 20px;
    font-weight: 500;
}

.nav-brand >span{
    font-size: x-large;
    cursor: pointer;
    transition: color 0.3s ease;


}
.nav-brand >span:hover{
    color: lightgrey;
}
.nav-opt{
    display: flex;
    list-style: none;
    flex-direction: row;
    font-size: large;
    font-weight: 500;
    gap: 40px;
    /* line-height: 10px; */
    
}

.options{
    cursor: pointer;
    transition: color 0.3s ease;
    line-height: 2;
}
.options:hover{
    color: lightgrey;
}