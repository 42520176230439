@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.login-page{
  background-image: linear-gradient(rgb(244, 197, 111),white,rgb(136, 243, 136));
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-page-container {
  background-image: linear-gradient(rgb(250, 233, 199),white,rgb(201, 240, 201));
    height: 70%;
    display: grid;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    width: 410px;
}
.login-page-logo{
    position: absolute;
    top: 10px;
    left: 68px;
    align-items: center;
    display: flex;
    font-family:montserrat ;
    gap: 10px;
    font-size: 28px;
    font-weight: 500;
    color: white;
}

.logo {
    width: 3rem;
    margin: 0;
   
  }
  /* background-image: linear-gradient(rgb(234, 215, 178),white,rgb(193, 243, 193)); */
  /*  */