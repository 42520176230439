.App {
  display: grid;
  place-items: center;
  height: 100vh;
  overflow: hidden;
}


button.mini-button {
  /* background-color: #efefef;  */

  color: #444442; 
  border-radius: 5px;
  padding: 2px 4px; 
  cursor: pointer; 
  transition: background-color 0.3s ease ;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

button.mini-button:hover {
  background-color: #cfcece;

}

button.mini-button:disabled {
  /* background-color: #f6f6ee;  */
  color: #b4a9a9a2;

  cursor: not-allowed;
  box-shadow: none;
}

button.mini-button svg {
  width: 30px; 
  height: 30px;
}
