@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
.login-form-container {
  text-align: left;
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: 20px;
  width: 80%;
}
.login-form-container div h1 {
  font-size: xx-large;
  font-weight: 400;
}
.login-form-container-text {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.login-form-container-text span {
  display: flex;
  flex-direction: column;
  letter-spacing: 1px;
  color: #9e9e9e;
  font-size: large;
  width: 100%;
  gap: 10px;
}

form {
  display: flex;
  flex-direction: column;
  gap: 27px;
}

.email-field,
.password-field {
  display: flex;
  flex-direction: column;
  position: relative;
}

.email-field label,
.password-field label {
  padding-bottom: 2px;
}

.email-field input,
.password-field input {
  border: 0.05rem solid #007bff;
  height: 40px;
  font-size: 16px; /* Consistent font size */
  padding: 0 8px;
  border-radius: 5px;
  width: 100%;
}

/* .email-field input::placeholder,
.password-field input::placeholder {
 border: 1px solid red; 
 line-height: 1;
} */


.eye-toggler {
  position: absolute;
  margin-top: 12%;
  margin-left: 90%;
  border: none;
}

.login-button {
  width: 100%;
  color: white;
  height: 2.75rem;
  background-color: #007bff;
  border: none;
  padding: 4px;
  margin-top: 1rem;
  border-radius: 4px;
  font-weight: 200;
  font-size: medium;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #91beee;
}
.login-button:active {
  background-color: #4596ed;
}
.login-button:disabled {
  cursor: not-allowed;
  background: lightgray;
}

.error {
  position: absolute;
  font-size: 12px !important;
  margin: 20% 0%;
  color: red;
  font-size: smaller;
}

.login-button .loader {
  position: relative;
  top: auto;
  left: 45%;
}
