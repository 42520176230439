@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.logout-modal {
  display: flex  !important;
  align-items: center  !important;
  justify-items: center  !important;
  width: 350px  !important;
  height: 210px  !important;
}

.logout-modal-body{
    place-items: center !important;
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    gap: 22px;
}

.logout-modal-buttons {
  justify-content: space-between  !important;
  width: 80%  !important;
  margin-bottom: 10px;
}

.logout-warning-icon {
  color: #ef4044 !important;
}

.logout-confirmation-text {
  font-family: montserrat  !important;
  text-align: center;
}
.logout-task-modal-cancel-button {
  color: #007bff  !important;
  font-family: montserrat !important;
  border: 2px solid #007bff !important;
  width: 110px;
}

.logout-task-modal-logout-button {
  color: white !important;
  background-color: #ef4044 !important;
  font-family: montserrat !important;
  width: 110px !important;
  transition: background-color 0.3s ease, transform 0.3s ease !important;
  
}

.logout-task-modal-logout-button:hover{
  background-color: #d56c6e !important;
}