/* Center the content */
.page-not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
  }
  
  .page-not-found h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .page-not-found p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .button {
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s;
    margin: 0.5rem;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
  .button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  