@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.file-download-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin:0% 4.5%
}


.file-download-container .download-button{

    color:white !important;
    background-color: #007bff !important;
    transition: background-color 0.3s ease !important;
    cursor: pointer;
}
 .file-download-container .download-button:hover{
  background-color: #8fc1f7;
 }
 .file-download-container .download-button:active{
  background-color: #3290f5;
 }
 .file-download-container :disabled{
    cursor: not-allowed;
    background-color: white;
 }

 .result-task-name {
    /* position: fixed;
    top: 13%;
    left: 5%; */
    font-size: xx-large;
    font-weight: 600;
    /* margin-bottom: 30px; */
  }
  