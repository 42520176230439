@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.file-data-container {
  overflow: auto;
  margin: auto;
  height: 100%;
  width: 91%;
  font-family: montserrat;
  display: flex;
  flex-direction: column;
  gap: 30px;
  /* padding-right: 14px; */
}

.result-container {
  height: fit-content;
  width: 100%;
}

.result-message {
  font-weight: 500;
  font-size: large;
}

/* Center header name text vertically */

.row-download-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #007eff;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: color 0.3s ease;
}
.row-download-button:hover {
  color: #98c4f0;
}

.device-details-container>span , .result-container>span{
    font-size: medium;
    font-weight: 600;
}



.device-details-table, .result-table{
  height: fit-content !important;
}

/* [role="presentation"]{
  display: none;
} */
/*
.file-data-container::-webkit-scrollbar {
    display: none;
}

.row-header{
    display: grid;
    grid-template-columns: 1fr 2fr;
    background-color: #f9f9f9;
    font-size: 30px;
    font-weight: 700;
    border-bottom: 2px solid grey;

}

.key-header {
    padding: 14px 0px 14px 30%;
    border-right: 1px solid grey;
}

.value-header{
    padding: 14px 0px 14px 30%;
    border-left: 1px solid grey;

}

.row{
    display: grid;
    grid-template-columns: 1fr 2fr;
    font-weight: 500;
    font-size: 20px;
}

.row:nth-child(2n){
    background: #efefef;
}

.row:nth-child(2n+1){
    background-color: #f9f9f9;
}

.key {
    padding: 10px 0px 10px 30%;
    border-right: 1px solid grey;
}

.value{
    padding: 10px 0px 10px 30%;
    border-left: 1px solid grey;
} */
